<template>
  <b-container fluid>
    <b-row>
      <b-col md="12">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">{{ $t("sidebar.periods") }}</h4>
          </template>
          <template v-slot:headerAction>
            <b-button variant="primary" @click="$refs.modalPeriods.show()">{{
              $t("app.new")
            }}</b-button>
          </template>
          <template v-slot:body>
            <b-row>
              <b-col sm="12" md="1" class="pr-1 mb-2">
                <b-form-select
                  :options="['5', '10', '20', '50', '100']"
                  v-model="filter.per_page"
                  size="sm"
                  @input="getData()"
                ></b-form-select>
              </b-col>
              <b-col lg="3" md="6" sm="12" class="ml-auto mb-2">
                <b-input-group>
                  <b-form-input
                    id="filter-input"
                    :disabled="
                      !Array.isArray(getPeriods.data) ||
                      (getPeriods.data.length === 0 && periodsLoading === false)
                    "
                    v-model="filter.search"
                    type="search"
                    :placeholder="$t('app.search')"
                  ></b-form-input>
                </b-input-group>
              </b-col>

              <b-col md="12" class="table-responsive">
                <b-table
                  class="table mb-0 table-borderless"
                  :items="getPeriods.data"
                  :fields="columns"
                  :per-page="getPeriods.per_page"
                  :busy="
                    !Array.isArray(getPeriods.data) ||
                    getPeriods.data.length === 0
                      ? true
                      : false || periodsLoading == true
                  "
                  @filtered="onFiltered"
                >
                  <template #table-busy>
                    <div class="text-center text-danger my-2">
                      <div v-if="periodsLoading">
                        <b-spinner type="grow" class="align-middle"></b-spinner>
                        <strong>{{ $t("app.loading") }}...</strong>
                      </div>
                      <span class="h5" v-else>{{ $t("app.not-found") }}</span>
                    </div>
                  </template>
                  <template v-slot:cell(start_date)="data">
                    {{ formatDate(data.item.start_date) }}
                  </template>
                  <template v-slot:cell(end_date)="data">
                    {{ formatDate(data.item.end_date) }}
                  </template>

                  <template v-slot:cell(action)="data">
                    <b-button
                      variant=" iq-bg-warning rounded"
                      class="mr-1"
                      size="sm"
                      @click="$refs.modalPeriods.show(data.item)"
                      ><i class="ri-ball-pen-fill ml-1"></i
                    ></b-button>
                    <b-button
                      @click="deleteItem(data.item.id)"
                      variant=" iq-bg-danger rounded"
                      size="sm"
                      ><i class="ri-delete-bin-line ml-1"></i
                    ></b-button>
                  </template>
                </b-table>
                <b-pagination
                  class="float-right mt-3"
                  v-model="filter.page"
                  :total-rows="getPeriods.total"
                  :per-page="getPeriods.per_page"
                  first-number
                  pills
                  size="sm"
                  @change="getData"
                ></b-pagination>
                <p class="mt-3">
                  {{ $t("app.registers") }} {{ getPeriods.to }}
                  {{ $t("app.of") }} {{ getPeriods.total }}. Total
                  {{ getPeriods.total }}
                </p>
              </b-col>
            </b-row>
          </template>
        </iq-card>
      </b-col>
    </b-row>
    <modal-form ref="modalPeriods" @refresh="getData()" />
  </b-container>
</template>
<script>
import { core } from "@/config/pluginInit";
import modalForm from "./modalForm.vue";
import { mapActions, mapGetters } from "vuex";
import moment from "moment";
import _ from "lodash";

export default {
  name: "Courses",
  components: {
    modalForm,
  },
  mounted() {
    core.index();
    this.debouncedGetAnswer = _.debounce(this.getData, 500);
    this.getData();
  },
  watch: {
    "filter.search": {
      handler() {
        this.debouncedGetAnswer();
      },
    },
  },
  methods: {
    ...mapActions({
      fetchPeriods: "fetchPeriods",
      deletePeriods: "deletePeriods",
    }),
    getData(page = 1) {
      this.filter.page = page;
      this.fetchPeriods(this.filter);
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    formatDate(date) {
      return moment(date).format("DD/MM/YYYY");
    },
    deleteItem(id) {
      this.$bvModal
        .msgBoxConfirm(this.$t("app.body-confirm-delete"), {
          title: this.$t("app.title-confirm-delete"),
          size: "sm",
          buttonSize: "sm",
          okVariant: "success rounded",
          cancelVariant: "outline-danger rounded",
          okTitle: this.$t("app.yes"),
          cancelTitle: this.$t("app.not"),
          headerClass: "text-center",
          bodyClass: "text-center text-danger",
          footerClass: "p-2",
          hideHeaderClose: false,
          centered: true,
        })
        .then(async (value) => {
          if (value) {
            const resp = await this.deletePeriods(id);
            this.getData();
            core.showSnackbar("success", this.$t("app.register-delete"));
          }
        })
        .catch((err) => {
          // An error occurred
        });
    },
  },
  data() {
    return {
      filter: {
        paginate: true,
        page: 1,
        per_page: 10,
        search: null,
      },
      totalRows: 1,
    };
  },
  computed: {
    ...mapGetters({
      getPeriods: "getPeriods",
      periodsLoading: "periodsLoading",
    }),
    columns() {
      return [
        { label: this.$t("periods.name"), key: "name", class: "text-center" },
        {
          label: this.$t("periods.start_date"),
          key: "start_date",
          class: "text-center",
        },
        {
          label: this.$t("periods.end_date"),
          key: "end_date",
          class: "text-center",
        },
        { label: this.$t("app.action"), key: "action", class: "text-center" },
      ];
    },
  },
};
</script>
