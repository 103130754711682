<template>
  <b-modal id="modal-periods" hide-footer scrollable>
    <template #modal-header>
      <h4>
        {{ data.id === null ? $t("periods.new") : $t("periods.edit") }}
      </h4>
    </template>
    <ValidationObserver v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(onSubmit)">
        <div class="new-user-info">
          <b-row class="justify-content-md-center">
            <b-form-group
              class="col-md-12"
              :label="$t('periods.name')"
              label-for="name"
            >
              <ValidationProvider
                :name="$t('periods.name')"
                rules="required"
                v-slot="{ errors }"
              >
                <b-form-input
                  v-model="data.name"
                  type="text"
                  :placeholder="$t('periods.name')"
                  :class="errors.length > 0 ? ' is-invalid' : ''"
                >
                </b-form-input>
                <div class="invalid-feedback">
                  <span>{{ errors[0] }}</span>
                </div>
              </ValidationProvider>
            </b-form-group>
            <b-form-group
              class="col-md-6"
              :label="$t('periods.start_date')"
              label-for="acronym"
            >
              <ValidationProvider
                :name="$t('periods.start_date')"
                rules="required"
                v-slot="{ errors }"
              >
                <b-form-input
                  v-model="data.start_date"
                  :min="today"
                  type="date"
                  :placeholder="$t('periods.start_date')"
                  :class="errors.length > 0 ? ' is-invalid' : ''"
                >
                </b-form-input>
                <div class="invalid-feedback">
                  <span>{{ errors[0] }}</span>
                </div>
              </ValidationProvider>
            </b-form-group>
            <b-form-group
              class="col-md-6"
              :label="$t('periods.end_date')"
              label-for="education"
            >
              <ValidationProvider
                :name="$t('periods.end_date')"
                rules="required"
                v-slot="{ errors }"
              >
                <b-form-input
                  :min="data.start_date"
                  v-model="data.end_date"
                  type="date"
                  :placeholder="$t('periods.end_date')"
                  :class="errors.length > 0 ? ' is-invalid' : ''"
                >
                </b-form-input>
                <div class="invalid-feedback">
                  <span>{{ errors[0] }}</span>
                </div>
              </ValidationProvider>
            </b-form-group>
          </b-row>
          <hr />
          <b-button
            :disabled="periodsLoading"
            variant="success"
            class="float-right"
            type="submit"
          >
            <span v-if="periodsLoading">
              <b-spinner small type="grow"></b-spinner>
              {{ $t("app.loading") }}...
            </span>
            <span v-else>
              {{ $t("app.save") }}
            </span>
          </b-button>
          <b-button
            class="float-right mr-1"
            @click="$bvModal.hide('modal-periods')"
            variant="outline-danger"
            type="reset"
          >
            {{ $t("app.cancel") }}
          </b-button>
        </div>
      </form>
    </ValidationObserver>
  </b-modal>
</template>
<script>
import { core } from "@/config/pluginInit";
import { mapActions, mapGetters } from "vuex";
import moment from "moment";

export default {
  name: "modalForm",
  mounted() {
    !this.data.id ? (this.today = moment().format("YYYY-MM-DD")) : "";
    core.index();
  },
  watch: {
    "data.start_date": {
      handler() {
        let start_date = moment(this.data.start_date).format("YYYY-MM-DD");
        let end_date = moment(this.data.end_date).format("YYYY-MM-DD");
        if (start_date > end_date) {
          this.data.end_date = "";
        }
        if (start_date < this.today) {
          this.data.start_date = "";
          this.data.end_date = "";
        }
      },
    },
  },
  data() {
    return {
      data: {
        id: null,
        name: null,
        start_date: null,
        end_date: null,
      },
      today: null,
    };
  },
  methods: {
    ...mapActions({
      saveForm: "storePeriods",
    }),
    async show(item) {
      this.reserForm();
      if (item) {
        this.data = {
          id: item.id,
          name: item.name,
          start_date: item.start_date,
          end_date: item.end_date,
        };
      }
      this.$bvModal.show("modal-periods");
    },
    async onSubmit() {
      let payload = {
        id: this.data.id,
        name: this.data.name,
        start_date: moment(this.data.start_date).format("YYYY-MM-DD"),
        end_date: moment(this.data.end_date).format("YYYY-MM-DD"),
      };
      const resp = await this.saveForm(payload);
      this.$bvModal.hide("modal-periods");
      this.$emit("refresh");
      core.showSnackbar(
        "success",
        !this.data.id
          ? this.$t("app.success-create")
          : this.$t("app.success-edit")
      );

      this.reserForm();
    },
    reserForm() {
      this.data = {
        id: null,
        name: null,
        start_date: null,
        end_date: null,
      };
    },
  },
  computed: {
    ...mapGetters({
      periodsLoading: "periodsLoading",
    }),
  },
};
</script>
